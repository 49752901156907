
import { defineComponent, PropType } from "vue";

import { compareFormat } from "@/utils/dateFormat";
export default defineComponent({
    name: "ClassSchCard",
    props: {
        courseChapterList: Array as PropType<object[]>,
        itemBool: {
            type: Number,
            default: 0
        },
        summaryBool: {
          type: Boolean,
          default: true
        },
        isBZWZ: {
          type: Boolean,
          default: true
        },
        isLogin: {
          type: Boolean,
          default: false
        }
    },
    setup(props) {
        return {
            compareFormat
        }
    }
});
