
import { defineComponent, ref, onMounted, nextTick, onUnmounted, reactive, watch, inject } from "vue";
import { useRoute } from "vue-router";

import dateFormat, { timeFormat } from "@/utils/dateFormat";
import ClassIntro from "@/components/common/ClassIntro.vue";
import ClassSchCard from "@/components/common/ClassSchCard.vue";
import ClassComment from "@/components/common/ClassComment.vue";
import api from "@/api/axios";
import axios from "@/api/axios";
import $ from "jquery";
import asyncLoadJs, { removeJs } from "@/hooks/useAsyncLoadJs";
import leftTime, { clearTime } from "@/hooks/useLeftTime";
import wx from "weixin-sdk-js";
import Swiper from "swiper";
import useClipboard from 'vue-clipboard3'
import Toast from "@/components/UI/Toast";
import Modal from "@/components/UI/Modal";
import { useRouter } from "vue-router";
import ClassFooter from "@/components/common/ClassFooter.vue";
import {consoleLog} from "echarts/types/src/util/log";
import router from "@/router";


export default defineComponent({
  name: "videoRealtime",
  components: {
    ClassIntro,
    ClassSchCard,
  },
  setup() {
    const shareTitle = ref('')
    const videoImgUrl = ref('')
    const route = useRoute();
    const id = ref(route.query.id);
    const recIndex = route.query.recIndex;
    // console.log("rec",recIndex);
    const vip = route.query.vip
    const fenlei = route.query.fenlei
    const intro = ref({});
    const detail = ref({});
    const courseChapterList = ref<object>([]);
    const live = ref({});

    const showCode=ref(true);
    // const plist = reactive<object[]>([]);
    const plist = reactive({data: []});
    const router = useRouter();
    const liveProductSwitch = ref()
    const liveSwitchTab = ref(true)
    const menu = ref()
    const menuBack = ref()
    const urlData = ref()
    const uid = ref()
    const codeImg = ref()
    const RecList = ref<object>([]);
    const comment = ref("");
    const errorMsgStr =ref();
    const user = inject("userInfo") as object;

    function getRenderData(id: any,index: any) {
      api
      .get("/M/Discover/VideoRealtime/" + id, {
        params: {
          recIndex: index,
          vip: vip,
          fenlei: fenlei,
        },
      })
      .then((res) => {
        if (res.data.success == false) {
          if(res.data.msg =='请先登录再执行此操作'){
            Toast({
              type: "error",
              title: res.data.msg,
              onClose: function () {
                router.push({
                  name: "Login",
                  query: {
                    redirect: router.currentRoute.value.fullPath,
                  },
                });
              }
            });
          }else{
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        }else{
          const data = res.data.obj;
          errorMsgStr.value = data.errorMsg;
          if(data.errorMsg=="NO_RECORD"){
            Toast({
              type: "error",
              title: "暂无回放",
            });
          }
          shareTitle.value = data.course.courseName//安卓微信分享获取的title
          document.title = data.course.courseName + "-伟业计量";//苹果微信分享获取的title
          route.meta.content.description = data.course.courseDescription;
          liveProductSwitch.value = data.course.liveType
          if (data.course.liveType == 0) {
            liveSwitchTab.value = false
            if (data.live.liveStatus==1) {
              menuBack.value = ["课程概述", "回放目录(0)", "聊天"];
            } else {
              menuBack.value = ["课程概述", "回放目录(0)"];
            }
          } else {
            liveSwitchTab.value = true
            menu.value = ["介绍", "聊天"];
            menuBack.value = ["课程概述", "回放目录(0)"];
          }
          if(data.course.liveAward){
            data.course.liveAward = data.course.liveAward.replace(/\n/g,"<br/>").replace(/\s/g, "&nbsp;");
          }
          intro.value = data.course;
          detail.value = data;
          videoImgUrl.value = data.videoImgUrl;
          courseChapterList.value = data.courseChapterList;
          live.value = data.live || '';
          uid.value = data.currentUser && data.currentUser.id;
          // console.log('uid',uid.value )
          RecList.value = data.recList;
          menuBack.value[1] = "回放目录(" + data.recList.length + ")";

          nextTick(() => {
            asyncLoadJs("js/jquery-3.1.1.min.js", "videoRealtime").then(() => {
              if (data.live.liveStatus==3) {
                asyncLoadJs("js/playbackSDK.js", "videoRealtime").then(() => {
                  asyncLoadJs("js/bncclive/livePlayBack.js", "videoRealtime");
                });
              } else {
                asyncLoadJs("js/base.js", "videoRealtime").then(() => {
                  asyncLoadJs("js/liveSDK.JS", "videoRealtime").then(() => {
                    asyncLoadJs("js/bncclive/videoRealtime.js", "videoRealtime");
                  });
                });
              }
            });
            leftTime(data.course.liveStartTime, function (d: any) {
              if (d.status) {
                const $dateShow1 = $("#dateShow");
                $dateShow1.find(".d").html(d.d);
                $dateShow1.find(".h").html(d.h);
                $dateShow1.find(".m").html(d.m);
                $dateShow1.find(".s").html(d.s);
              }
            })
          });

        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
    getRenderData(id.value,recIndex)
    function toLogin() {
      router.push({
        path: "/login",
        query: {
          redirect: router.currentRoute.value.fullPath
        }
      });
    }
    function contrast(startDate: string) {
      return new Date().getTime() - new Date(timeFormat(startDate)).getTime() < 0
        ? true
        : false;
    }
    //  切换列表菜单
    // const menu = ref(["直播介绍", "互动聊天", "提问", "邀请栏", "公告", "商品栏"]);
    const itemBool = ref(0);
    const itemBoolBack = ref(0)
    function selectMenu(item: string) {
      if (item == "介绍") {
        itemBool.value = 0;
      }
      if (item.indexOf("聊天") != -1) {
        itemBool.value = 1;
      }
      // 广播通知位置修改
      if (itemBool.value == 1) {
        if ($(".vrt-radio-list").attr('style') == 'display: block;') {
          nextTick(() => {
            const vrtRadioHeight = $('.vrt-radio-list').outerHeight()
            $('.vrt-talk-list-ul').css('padding-top', ((vrtRadioHeight as any)+10)+'px')
          })
        }
      } else {
        $('.vrt-talk-list-ul').removeAttr('style')
      }
    }
    function selectMenuBack(item: string) {
      if (item == "课程概述") {
        itemBoolBack.value = 0;
      }
      if (item.indexOf("回放目录") != -1) {
        itemBoolBack.value = 1;
      }
    }

    onMounted(() => {
        new Swiper("#menu-nav-swiper", {
          initialSlide: 0,
          slidesPerView: 5,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },

          },
          // onSlideChangeEnd: function(){
          //   }
        });
    })


    onUnmounted(() => {
      removeJs("jquery-3.1.1.min.js")
      removeJs("base.js")
      removeJs("liveSDK.JS")
      removeJs("videoRealtime.js")
      removeJs("playbackSDK.js")
      removeJs("livePlayBack.js");

      removeJs("socket.io.js")
      removeJs("swfobject.js")
      removeJs("json3.min.js")
      removeJs("report.js")
      removeJs("dpc.js")
      //销毁当前页面的倒计时器
      clearTime();
    })


    const { toClipboard } = useClipboard()
    watch(
            [
              () => {
                return shareTitle.value;
              }
            ],
            (to, from) => {
              const isweixin = ref(false);
              const ua = navigator.userAgent.toLowerCase();
              const reg = /MicroMessenger/i;
              isweixin.value = reg.test(ua);
              if (isweixin.value) {
                nextTick(() => {
                  const link = window.location.href;
                  const formData = new FormData();
                  formData.append("url", link);
                  axios.post("/M/Server/getweixin", formData).then((res: any) => {
                    const data = res.data;
                    wx.config({
                      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                      appId: data.appid, // 必填，公众号的唯一标识
                      timestamp: parseInt(data.timestamp), // 必填，生成签名的时间戳
                      nonceStr: data.nonceStr, // 必填，生成签名的随机串
                      signature: data.str, // 必填，签名
                      jsApiList: [
                        "checkJsApi",
                        "updateAppMessageShareData",
                        "updateTimelineShareData",
                      ], // 必填，需要使用的JS接口列表
                    });
                    wx.ready(function () {
                      //需在用户可能点击分享按钮前就先调用

                      wx.updateAppMessageShareData({
                        title: to+'-伟业计量', // 分享标题
                        desc: route.meta.content.description, // 分享描述
                        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: "http://m.bzwz.com/img/wxshare/logo-weiyegg.png", // 分享图标
                        success: function () {
                          // 设置成功
                        },
                      });
                      wx.updateTimelineShareData({
                        title: to+'-伟业计量', // 分享标题
                        desc: route.meta.content.description, // 分享描述
                        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: "http://m.bzwz.com/img/wxshare/logo-weiyegg.png", // 分享图标
                        success: function () {
                          // 设置成功
                        },
                      });
                      wx.onMenuShareTimeline({
                        title: to+'-伟业计量', // 分享标题
                        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: "http://m.bzwz.com/img/wxshare/logo-weiyegg.png", // 分享图标
                        success: function () {
                          // 用户点击了分享后执行的回调函数

                        }
                      })
                      wx.onMenuShareAppMessage({
                        title: to+'-伟业计量', // 分享标题
                        desc: route.meta.content.description, // 分享描述
                        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: "http://m.bzwz.com/img/wxshare/logo-weiyegg.png", // 分享图标
                        success: function () {
                          // 用户点击了分享后执行的回调函数
                        }
                      })

                    });
                  });
                });
              }
            }
    );
    watch(
      () => {
        return menu.value
      },
      () => {
        new Swiper("#menu-nav-swiper", {
          initialSlide: 0,
          slidesPerView: 5,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
        });
      }
    )
    watch(
      () => {
        return menuBack.value
      },
      () => {
        new Swiper("#menu-nav-swiper", {
          initialSlide: 0,
          slidesPerView: 5,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
        });
      }
    )

    watch(
      [
        () => {
          return route.query;
        },
        () => {
          return route.params;
        },
      ],
      (to, from) => {
        // 避免登录时触发页面刷新
        const index=String(to[0].recIndex)
        if (to[0].id || index) {
          id.value = to[0].id;
          getRenderData(to[0].id as string,index as any);

        }
      }
    );

    return {
      contrast,
      intro,
      uid,
      courseChapterList,
      live,
      menu,
      menuBack,
      selectMenu,
      selectMenuBack,
      itemBool,
      itemBoolBack,
      detail,
      videoImgUrl,
      plist,
      liveSwitchTab,
      urlData,
      confirm,
      close,
      codeImg,
      RecList,
      dateFormat,
      // listComment,
      comment,
      errorMsgStr,
      showCode,
      toLogin,
      // fenlei
    };
  },
});
