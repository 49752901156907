import { render } from "./VideoRealtimeBncc.vue?vue&type=template&id=59386ac0&scoped=true"
import script from "./VideoRealtimeBncc.vue?vue&type=script&lang=ts"
export * from "./VideoRealtimeBncc.vue?vue&type=script&lang=ts"

import ".././assets/styles/videoRealtime.css?vue&type=style&index=0&lang=css"
import "./VideoRealtimeBncc.vue?vue&type=style&index=1&id=59386ac0&lang=scss&scoped=true"
import "./VideoRealtimeBncc.vue?vue&type=style&index=2&id=59386ac0&lang=css"
script.render = render
script.__scopeId = "data-v-59386ac0"

export default script